import React from 'react';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../providers/AuthProvider';
import FullScreenLoading from '../FullScreenLoading';

const AgentRoute: React.FC<{
  element: React.ReactElement;
  publicRedirect?: string;
}> = (props) => {
  const { t } = useTranslation('COMMON');
  const [{ user, appLoaded, isAuthenticated }] = useAuth();
  const { element: PrivateElement, publicRedirect = '/' } = props;

  if (appLoaded)
    return isAuthenticated && user?.role?.type === 'rva' ? PrivateElement : <Navigate replace to={publicRedirect} />;

  return <FullScreenLoading loading title={t('LOADING')} subtitle={t('LOADING_SUBTITLE')} />;
};

export default AgentRoute;
