import { AuthAction, AuthActionType } from '../../providers/AuthProvider';
import { AlertAction, AlertType } from '../../providers/AlertProvider';
import { ShoppingCartOverlayAction } from '../../providers/ShoppingCartOverlayProvider';
import { me } from '../fetch/auth';
import { IProduct, IProductAttributes } from '../fetch/products';
import {
  create as _createCartItem,
  update as _updateCartItem,
  _delete as _deleteCartItem,
  ICartItemCreate,
  ICartItemUpdate,
  ICartItem,
} from '../fetch/cartItems';
import { create as _createCart, update as _updateCart, ICartCreate, ICartUpdate } from '../fetch/carts';
import { IUser } from '../interfaces';
import { ITotals } from '../../components/Subtotal';
import { AxiosResponse } from 'axios';
import { IErrorImport, IProductWithQuantity } from '../../components/Import/utils';
import { getExtraDiscountPrice, getProductPrice } from './promotions';
import moment from 'moment';

const createCartItem = async (token: string, product: IProduct, quantity: number) => {
  const price = product.attributes.price_net || 0;
  // TODO: use real customPrice. Set it as list price ATM.
  const customPrice = price;
  const discount = price - customPrice;
  // TODO: use real taxes.
  const taxes = 0;
  const cartItem: ICartItemCreate = {
    quantity,
    price,
    discount,
    taxes,
    shipping: 0, // Set by the backend, the value set here is ignored. Left for compatibility.
    product: product.id,
    promotions: product.attributes.promotions,
    priceDiscounted: getExtraDiscountPrice(product.attributes.priceDiscounted, quantity),
  };

  return _createCartItem(token, cartItem);
};

const updateCartItem = async (
  token: string,
  cartItem: ICartItem,
  product: IProduct,
  quantity: number,
  excerpt?: boolean,
) => {
  const price = product.attributes.price_net || (product.attributes as any).attributes.price_net || 0;

  // TODO: use real customPrice. Set it as list price ATM.
  const customPrice = price;
  const discount = price - customPrice;
  // TODO: use real taxes.
  const taxes = 0;
  // TODO: use real shipping.
  const shipping = 0;
  const cartItemUpdate: ICartItemUpdate = {
    id: cartItem.id,
    quantity,
    price,
    discount,
    taxes,
    shipping,
    product: product.id,
    promotions: product.attributes.promotions,
    priceDiscounted: getExtraDiscountPrice(product.attributes.priceDiscounted, quantity),
    excerpt,
  };

  return _updateCartItem(token, cartItemUpdate);
};

export const deleteCartItem = async (token: string, user: IUser, cartItem: ICartItem) => {
  const { error: deleteCartItemError } = await _deleteCartItem(token, cartItem);
  if (deleteCartItemError)
    return {
      error: deleteCartItemError,
    };

  const { id: userId, cart } = user;
  const { id: cartId } = cart!;
  let { cart_items: cartItems } = cart!;
  const { shipping_address } = cart!;
  const shippingAddressId = shipping_address && shipping_address.id;
  cartItems = cartItems || [];

  const productForPromo = {
    id: cartItem.product.id,
    attributes: {
      ...cartItem.product,
      promotions: cartItem.promotions,
    } as unknown as IProductAttributes,
  } as IProduct;
  const { productFree } = checkAndApplyPromotionsFreeProduct(productForPromo, 0);
  let freeProductAlreadyInCartId: number | null = null;

  // If the product is not free, check if there is a linked free product in the cart
  if (cartItem.price !== 0) {
    if (productFree) {
      const freeProductAlreadyInCart = cartItems.find((cartItem: ICartItem) => {
        const { product: cartItemProduct } = cartItem;
        const { id: cartItemProductId } = cartItemProduct || {};
        return cartItemProductId === productFree.id && cartItem.price === 0 && productFree.attributes.price_net === 0;
      });

      // If the product free is already in the cart, delete it, because we are deleting the linked paid product
      if (freeProductAlreadyInCart) {
        freeProductAlreadyInCartId = freeProductAlreadyInCart.id;
        await _deleteCartItem(token, freeProductAlreadyInCart);
      }
    }
  }

  const updatedCartItems = cartItems
    .map(({ id }) => id)
    .filter((id) => id !== cartItem.id && id !== freeProductAlreadyInCartId);
  const cartUpdate: ICartUpdate = {
    id: cartId,
    users_permissions_user: userId,
    customer: user.customer.id,
    cart_items: updatedCartItems,
    shipping_address: updatedCartItems.length ? shippingAddressId : null,
  };

  return _updateCart(token, cartUpdate);
};

export interface IFlushCartWithFeedback {
  dispatchAlertChange: (action: AlertAction) => void;
  dispatchAuthChange: (action: AuthAction) => void;
  setLoading: (adding: boolean) => void;
  t: (key: string) => string;
  token: string;
  user: IUser;
  lang?: string;
}

export const flushCartWithFeedback = async ({
  dispatchAlertChange,
  dispatchAuthChange,
  setLoading,
  t,
  token,
  user,
  lang,
}: IFlushCartWithFeedback) => {
  const { id: userId, cart } = user;
  const { id: cartId } = cart!;
  let { cart_items: cartItems } = cart!;
  cartItems = cartItems || [];
  for (let cartItem of cartItems) {
    await _deleteCartItem(token, cartItem);
  }
  const cartUpdate: ICartUpdate = {
    id: cartId,
    users_permissions_user: userId,
    cart_items: [],
    customer: user.customer.id,
    shipping_address: null,
  };
  await _updateCart(token, cartUpdate);

  return updateUserCart({
    dispatchAlertChange,
    dispatchAuthChange,
    setLoading,
    t,
    token,
    lang,
  });

  ////
  /*
  setLoading(true);
  const { error: deleteCartItemError } = await deleteCartItem(token!, user!, cartItem);
  if (deleteCartItemError) {
    setLoading(false);
    return dispatchAlertChange({
      open: true,
      message: t('SHOPPING_CART:UPDATE_ERROR'),
    });
  }
  setLoading(false);
  return updateUserCart({
    dispatchAlertChange,
    dispatchAuthChange,
    setLoading,
    t,
    token,
    lang,
  });
  */
};

export const flushCart = async (token: string, user: IUser) => {
  const { id: userId, cart } = user;
  const { id: cartId } = cart!;
  let { cart_items: cartItems } = cart!;
  cartItems = cartItems || [];
  for (let cartItem of cartItems) {
    await _deleteCartItem(token, cartItem);
  }
  const cartUpdate: ICartUpdate = {
    id: cartId,
    users_permissions_user: userId,
    cart_items: [],
    customer: user.customer.id,
    shipping_address: null,
  };
  return _updateCart(token, cartUpdate);
};

const createCart = async (token: string, user: IUser, product: IProduct, quantity: number) => {
  const createCartItemRes = await createCartItem(token, product, quantity);
  if (createCartItemRes.error)
    return {
      error: createCartItemRes.error,
    };
  if (!createCartItemRes.data)
    return {
      error: new Error('No data returned by createCartItem'),
    };

  const {
    data: { id: createdCartItemId },
  } = createCartItemRes.data;
  const newCartItems = [createdCartItemId];

  // Check if the product has some promotions
  const { productFree, productFreeQuantityWithLimit } = checkAndApplyPromotionsFreeProduct(product, quantity);

  // There is a product free and a quantity > 0
  if (productFree && productFreeQuantityWithLimit && productFreeQuantityWithLimit > 0) {
    const createFreeCartItemRes = await createCartItem(token, productFree, productFreeQuantityWithLimit);
    if (createFreeCartItemRes.error)
      return {
        error: createFreeCartItemRes.error,
      };
    if (!createFreeCartItemRes.data)
      return {
        error: new Error('No data returned by createCartItem (Free)'),
      };
    const {
      data: { id: createdFreeCartItemId },
    } = createFreeCartItemRes.data;
    newCartItems.push(createdFreeCartItemId);
  }

  const { id: userId } = user;
  const cart: ICartCreate = {
    users_permissions_user: userId,
    customer: user.customer.id,
    cart_items: newCartItems,
  };
  return _createCart(token, cart);
};

const createEmptyCart = async (token: string, user: IUser) => {
  const { id: userId } = user;
  const cart: ICartCreate = {
    users_permissions_user: userId,
    cart_items: [],
    customer: user.customer.id,
  };
  return _createCart(token, cart);
};

export const updateCartShippingAddress = async (token: string, user: IUser, shippingAddressId: number) => {
  const { id: userId, cart } = user;
  const { id: cartId } = cart!;
  let { cart_items: cartItems } = cart!;
  cartItems = cartItems || [];
  const cartUpdate: ICartUpdate = {
    id: cartId,
    users_permissions_user: userId,
    cart_items: cartItems.map(({ id }) => id),
    shipping_address: shippingAddressId,
    customer: user.customer.id,
  };
  return _updateCart(token, cartUpdate);
};

export const updateCart = async (
  token: string,
  user: IUser,
  product: IProduct,
  quantity: number,
  replaceQuantity = false,
  excerpt?: boolean,
) => {
  const { id: userId, cart } = user;
  const { id: cartId } = cart!;
  let { cart_items: cartItems } = cart!;
  cartItems = cartItems || [];
  const { shipping_address } = cart!;
  const shippingAddressId = shipping_address && shipping_address.id;
  const { id: addedProductId } = product;

  const itemAlreadyInCart = cartItems.find((cartItem: ICartItem) => {
    const { product: cartItemProduct } = cartItem;
    const { id: cartItemProductId } = cartItemProduct || {};
    return cartItemProductId === addedProductId;
  });

  if (itemAlreadyInCart) {
    const { quantity: alreadyInCartQuantity } = itemAlreadyInCart;
    const newQuantity = replaceQuantity ? quantity : quantity + alreadyInCartQuantity;

    // Check if the product has some promotions
    const { productFree, productFreeQuantityWithLimit } = checkAndApplyPromotionsFreeProduct(product, newQuantity);
    // There is a product free and a quantity > 0
    if (productFree && productFreeQuantityWithLimit !== null) {
      // Check if the product free is already in the cart
      const freeProductAlreadyInCart = cartItems.find((cartItem: ICartItem) => {
        const { product: cartItemProduct } = cartItem;
        const { id: cartItemProductId } = cartItemProduct || {};
        return cartItemProductId === productFree.id && cartItem.price === 0 && productFree.attributes.price_net === 0;
      });

      // If the product free is already in the cart, check if the quantity is correct
      if (freeProductAlreadyInCart) {
        // If the quantity is 0, delete the product free from the cart
        if (productFreeQuantityWithLimit === 0) {
          await _deleteCartItem(token, freeProductAlreadyInCart);
        } else {
          // If the quantity is not 0 and it is different from the previous one, update the quantity
          if (freeProductAlreadyInCart.quantity !== productFreeQuantityWithLimit)
            await updateCartItem(token, freeProductAlreadyInCart, productFree, productFreeQuantityWithLimit, excerpt);
        }
      }

      // If the product free is not in the cart and its quantity > 0, create it
      if (!freeProductAlreadyInCart && productFreeQuantityWithLimit > 0) {
        const createFreeCartItemRes = await createCartItem(token, productFree, productFreeQuantityWithLimit);
        if (createFreeCartItemRes.error)
          return {
            error: createFreeCartItemRes.error,
          };
        if (!createFreeCartItemRes.data)
          return {
            error: new Error('No data returned by createCartItem (Free)'),
          };
        const {
          data: { id: createdFreeCartItemId },
        } = createFreeCartItemRes.data;

        const cartUpdate: ICartUpdate = {
          id: cartId,
          users_permissions_user: userId,
          cart_items: [...cartItems.map(({ id }) => id), createdFreeCartItemId],
          shipping_address: shippingAddressId,
          customer: user.customer.id,
        };

        await _updateCart(token, cartUpdate);
      }
    }
    return updateCartItem(token, itemAlreadyInCart, product, newQuantity, excerpt);
  }

  const createCartItemRes = await createCartItem(token, product, quantity);
  if (createCartItemRes.error)
    return {
      error: createCartItemRes.error,
    };
  if (!createCartItemRes.data)
    return {
      error: new Error('No data returned by createCartItem'),
    };

  const {
    data: { id: createdCartItemId },
  } = createCartItemRes.data;
  const newCartItems = [createdCartItemId];

  const { productFree, productFreeQuantityWithLimit } = checkAndApplyPromotionsFreeProduct(product, quantity);
  if (productFree && productFreeQuantityWithLimit) {
    const createFreeCartItemRes = await createCartItem(token, productFree, productFreeQuantityWithLimit);
    if (createFreeCartItemRes.error)
      return {
        error: createFreeCartItemRes.error,
      };
    if (!createFreeCartItemRes.data)
      return {
        error: new Error('No data returned by createCartItem (Free)'),
      };
    const {
      data: { id: createdFreeCartItemId },
    } = createFreeCartItemRes.data;
    newCartItems.push(createdFreeCartItemId);
  }

  const cartUpdate: ICartUpdate = {
    id: cartId,
    users_permissions_user: userId,
    cart_items: [...cartItems.map(({ id }) => id), ...newCartItems],
    shipping_address: shippingAddressId,
    customer: user.customer.id,
  };

  return _updateCart(token, cartUpdate);
};

export const addToCart = async (token: string, user: IUser, product: IProduct, quantity: number) => {
  const { cart } = user;
  return cart ? updateCart(token, user, product, quantity) : createCart(token, user, product, quantity);
};

const addToCartMultiple = async (token: string, user: IUser, products: IProductWithQuantity[]) => {
  const { id: userId, cart } = user;
  const newCart = cart ? null : await createEmptyCart(token, user);
  const cartId = cart ? cart!.id : newCart!.data.data.id;
  const report: { productsNotAvailable: IErrorImport[]; availableProducts: string[] } = {
    productsNotAvailable: [],
    availableProducts: [],
  };

  let cartItems = cart ? cart.cart_items : newCart?.data.data.attributes.cart_items;
  cartItems = cartItems || [];
  const shipping_address = cart ? cart.shipping_address : null;
  const shippingAddressId = cart ? shipping_address && shipping_address.id : null;

  const replaceQuantity = false;
  const errors: boolean[] = [];
  const newProducts: number[] = [];

  for (const { product, quantity } of products) {
    const { id: addedProductId } = product;
    const itemAlreadyInCart = cartItems.find((cartItem: ICartItem) => {
      const { product: cartItemProduct } = cartItem;
      const { id: cartItemProductId } = cartItemProduct || {};
      return cartItemProductId === addedProductId;
    });
    if (itemAlreadyInCart) {
      const { quantity: alreadyInCartQuantity } = itemAlreadyInCart;
      const responseUpdateCart = await updateCartItem(
        token,
        itemAlreadyInCart,
        product,
        replaceQuantity ? quantity : quantity + alreadyInCartQuantity,
      );

      if (!responseUpdateCart.error?.data) {
        report.availableProducts = [...report.availableProducts, product.attributes.code];
      } else {
        const errDetails = responseUpdateCart?.error?.data?.error?.details;
        report.productsNotAvailable = [
          ...report.productsNotAvailable,
          { code: product.attributes.code, error: 0, details: errDetails },
        ];
      }

      continue;
    }

    const createCartItemRes = await createCartItem(token, product, quantity);
    const errDetails = createCartItemRes?.error?.data?.error?.details;

    if (createCartItemRes.error) {
      errors.push(true);

      report.productsNotAvailable = [
        ...report.productsNotAvailable,
        { code: product.attributes.code, error: 0, details: errDetails },
      ];
      continue;
    }
    // No data returned by createCartItem
    if (!createCartItemRes.data) {
      errors.push(true);
      report.productsNotAvailable = [
        ...report.productsNotAvailable,
        { code: product.attributes.code, error: 0, details: errDetails },
      ];
      continue;
    }

    const {
      data: { id: createdCartItemId },
    } = createCartItemRes.data;

    errors.push(false); // It can be also avoid, useful for debugging
    newProducts.push(createdCartItemId);

    report.availableProducts = [...report.availableProducts, product.attributes.code];
  }

  // Update the cart with the new products in massive way
  const cartUpdate: ICartUpdate = {
    id: cartId,
    users_permissions_user: userId,
    cart_items: [...cartItems.map(({ id }) => id), ...newProducts],
    shipping_address: shippingAddressId,
    customer: user.customer.id,
  };
  await _updateCart(token, cartUpdate);

  // Return if there is at least one error

  return { report, hasErrors: errors.includes(true) };
};

export interface IAddToCartWithFeedback {
  products: { product: IProduct; quantity: number }[];
  setAdding: (adding: boolean) => void;
  token: string;
  user: IUser;
  lang: string;
  dispatchAlertChange: (action: AlertAction) => void;
  dispatchAuthChange: (action: AuthAction) => void;
  dispatchCartOverlayChange: (action: ShoppingCartOverlayAction) => void;
  t: (key: string) => string;
  onClose?: () => void;
}

export const addToCartWithFeedback = async ({
  dispatchAlertChange,
  dispatchAuthChange,
  dispatchCartOverlayChange,
  products,
  setAdding,
  t,
  token,
  user,
  lang,
  onClose,
}: IAddToCartWithFeedback) => {
  let report: { productsNotAvailable: IErrorImport[]; availableProducts: string[] } = {
    productsNotAvailable: [],
    availableProducts: [],
  };
  try {
    if (!products || products.length === 0) return;
    setAdding(true);
    let hasErrors: Error | undefined | boolean | AxiosResponse<any, any> = false;

    // Managing case of massive import from CSV
    if (products.length === 1) {
      const { error: addError } = await addToCart(token!, user!, products[0].product, products[0].quantity);
      hasErrors = addError;

      //addToCart va refactorizzato per far coincidere i tipi di ritorno
      //@ts-ignore
      const errDetails = addError?.data?.error?.details;

      if (addError) {
        report.productsNotAvailable = [{ code: products[0]?.product.attributes.code, error: 0, details: errDetails }];
      } else {
        report.availableProducts = [products[0]?.product.attributes.code];
      }
    } else {
      const { hasErrors: _hasErrors, report: _report } = await addToCartMultiple(token!, user!, products);
      hasErrors = _hasErrors;
      report = _report;
    }

    if (hasErrors) {
      setAdding(false);
      if (products.length > 1) {
        dispatchAlertChange({
          type: AlertType.Info,
          open: true,
          message: t('SHOPPING_CART:PRODUCTS_NOT_AVAILABLE_WARNING'),
        });

        return report;
      }

      if (typeof hasErrors === 'object') {
        const errorData = (hasErrors as AxiosResponse<any, any>).data.error.details;
        if (errorData.hasOwnProperty('availabilityError') && errorData.availabilityError === true) {
          dispatchAlertChange({
            open: true,
            message: t('SHOPPING_CART:ERROR_AVAILABILITY'),
          });
          return report;
        } else if (errorData.hasOwnProperty('availabilityError') && errorData.availabilityError === false) {
          dispatchAlertChange({
            type: AlertType.Info,
            open: true,
            message: t('SHOPPING_CART:ERROR_MAX_AVAILABILITY') + ` (${errorData.availableQuantity}).`,
          });
          return report;
        } else {
          dispatchAlertChange({
            type: AlertType.Info,
            open: true,
            message: t('SHOPPING_CART:PRODUCTS_NOT_AVAILABLE_WARNING'),
          });
          return report;
        }
      }
    }
    const { error: meError, data: updatedUser } = await me(token!, lang);
    if (meError) {
      setAdding(false);
      dispatchAlertChange({
        open: true,
        message: t('LOGIN:ERROR'),
      });
      return dispatchAuthChange({
        type: AuthActionType.Logout,
      });
    }
    dispatchAuthChange({
      type: AuthActionType.SetUser,
      user: updatedUser,
    });
    setAdding(false);
    // In case of massive import from CSV, show a feedback message and close the modal
    if (products.length > 1) {
      dispatchAlertChange({
        message: t('PRODUCTS_ADDED_SUCCESS'),
        open: true,
        type: AlertType.Success,
      });
    }
    if (onClose) onClose();
    // 1 - Do not open cart immediately, to prevent a blocked scrolling bug.
    // 2 - Removed to open only after terms accepting
    // setTimeout(() => dispatchCartOverlayChange({ open: true }), 1000);
  } catch (e) {
    setAdding(false);
    dispatchAlertChange({
      open: true,
      message: t('SHOPPING_CART:ADD_ERROR'),
    });
  }

  return report;
};

interface IUpdateUserCart {
  dispatchAlertChange: (action: AlertAction) => void;
  dispatchAuthChange: (action: AuthAction) => void;
  setLoading: (adding: boolean) => void;
  t: (key: string) => string;
  token: string;
  lang?: string;
}

const updateUserCart = async ({
  dispatchAlertChange,
  dispatchAuthChange,
  setLoading,
  t,
  token,
  lang,
}: IUpdateUserCart) => {
  const { error: meError, data: updatedUser } = await me(token!, lang);
  if (meError) {
    setLoading(false);
    dispatchAlertChange({
      open: true,
      message: t('LOGIN:ERROR'),
    });
    return dispatchAuthChange({
      type: AuthActionType.Logout,
    });
  }

  dispatchAuthChange({
    type: AuthActionType.SetUser,
    user: updatedUser,
  });
  setLoading(false);
};

export interface IDeleteCartItemWithFeedback {
  cartItem: ICartItem;
  dispatchAlertChange: (action: AlertAction) => void;
  dispatchAuthChange: (action: AuthAction) => void;
  setLoading: (adding: boolean) => void;
  t: (key: string) => string;
  token: string;
  user: IUser;
  lang?: string;
}

export const deleteCartItemWithFeedback = async ({
  cartItem,
  dispatchAlertChange,
  dispatchAuthChange,
  setLoading,
  t,
  token,
  user,
  lang,
}: IDeleteCartItemWithFeedback) => {
  setLoading(true);
  const { error: deleteCartItemError } = await deleteCartItem(token!, user!, cartItem);
  if (deleteCartItemError) {
    setLoading(false);
    return dispatchAlertChange({
      open: true,
      message: t('SHOPPING_CART:UPDATE_ERROR'),
    });
  }
  setLoading(false);
  return updateUserCart({
    dispatchAlertChange,
    dispatchAuthChange,
    setLoading,
    t,
    token,
    lang,
  });
};

export interface IUpdateCartWithFeedback extends IDeleteCartItemWithFeedback {
  newQuantity: number;
  onClose?: () => void;
  excerpt?: boolean;
}

export const updateCartWithFeedback = async ({
  cartItem,
  dispatchAlertChange,
  dispatchAuthChange,
  newQuantity,
  excerpt,
  setLoading,
  t,
  token,
  user,
  lang,
  onClose,
}: IUpdateCartWithFeedback) => {
  setLoading(true);
  const product: IProduct = {
    id: cartItem.product.id,
    attributes: {
      ...cartItem.product,
      promotions: cartItem.promotions,
    } as unknown as IProductAttributes,
  };

  const { error: updateCartError } = await updateCart(token!, user!, product, newQuantity, true, excerpt);

  if (updateCartError) {
    setLoading(false);

    if (typeof updateCartError === 'object') {
      const errorData = (updateCartError as AxiosResponse<any, any>).data.error.details;
      if (errorData.hasOwnProperty('availabilityError') && errorData.availabilityError === true) {
        return dispatchAlertChange({
          open: true,
          message: t('SHOPPING_CART:ERROR_AVAILABILITY'),
        });
      } else if (errorData.hasOwnProperty('availabilityError') && errorData.availabilityError === false) {
        return dispatchAlertChange({
          open: true,
          message: t('SHOPPING_CART:ERROR_MAX_AVAILABILITY') + ` (${errorData.availableQuantity}).`,
        });
      } else {
        return dispatchAlertChange({
          open: true,
          message: t('SHOPPING_CART:UPDATE_ERROR'),
        });
      }
    }
  }
  if (onClose) onClose();
  return updateUserCart({
    dispatchAlertChange,
    dispatchAuthChange,
    setLoading,
    t,
    token,
    lang,
  });
};

export const calculateCartTotals = (cartItems: ICartItem[], cartShipping?: number): ITotals => {
  // Tot. Parziale listino
  const subTotal = cartItems.reduce((acc, cartItem) => {
    const { quantity, product } = cartItem;
    const { price_list: price } = product || {};
    return acc + (quantity * (price || 0) || 0) || 0;
  }, 0);

  // Sconto applicato
  const totalDiscount = cartItems.reduce((acc, cartItem) => {
    const { discount, quantity } = cartItem;
    return acc + (quantity * (discount || 0) || 0) || 0;
  }, 0);

  // IVA
  const totalTaxes = cartItems.reduce((acc, cartItem) => {
    const { quantity, taxes } = cartItem;
    return acc + (quantity * (taxes || 0) || 0) || 0;
  }, 0);

  // Spedizione
  const totalShipping =
    typeof cartShipping === 'number'
      ? cartShipping
      : cartItems.reduce((acc, cartItem) => {
          const { quantity, shipping } = cartItem;
          return acc + (quantity * (shipping || 0) || 0) || 0;
        }, 0);

  // Tot. Parziale netto
  const subTotalNet = cartItems.reduce((acc, cartItem) => {
    const { quantity } = cartItem;
    const price = getProductPrice(cartItem);
    return acc + quantity * price || 0;
  }, 0);

  const total = subTotalNet; //+ totalTaxes + totalShipping;

  return {
    subTotal,
    totalDiscount,
    totalTaxes,
    totalShipping,
    subTotalNet,
    total,
  };
};

const checkAndApplyPromotionsFreeProduct = (
  product: IProduct,
  quantity: number,
): { productFree: IProduct | null; productFreeQuantityWithLimit: number | null } => {
  const { attributes } = product;
  const { promotions } = attributes;
  const emptyResponse = { productFree: null, productFreeQuantityWithLimit: null };

  // If the poduct is free, or there are no promotions, skip promotions check
  if (attributes.price_net === 0 || !promotions?.data.length) return emptyResponse;

  // Check if the promotion is valid today
  const today = moment();
  const validFrom = moment(promotions.data[0]?.validFrom);
  const validTo = moment(promotions.data[0]?.validTo);
  if (validFrom.isAfter(today) || validTo.isBefore(today)) return emptyResponse;

  return emptyResponse;

  /*
  const promoCode = getPromotionTypeCode(promotions.data[0]?.attributes?.promotionType);
  if (!promoCode || (promoCode !== 'id:04##' && promoCode !== 'id:05##' && promoCode !== 'id:06##'))
    return emptyResponse;

  switch (promoCode) {
    case 'id:04##':
    case 'id:05##':
      if (promotions.data[0]?.attributes?.quantityX && promotions.data[0]?.attributes?.quantityY) {
        const { quantityX, quantityY } = promotions.data[0]?.attributes;
        let productFreeQuantity = 0;
        if (quantity >= quantityX) {
          // How many times the promotion is applied
          const promotionsNumber = Math.floor(quantity / quantityX);
          productFreeQuantity = promotionsNumber * (quantityX - quantityY);
        }
        const maxFreeNumber = promotions.data[0]?.attributes?.maxFreeNumber;
        const productFreeQuantityWithLimit =
          maxFreeNumber && productFreeQuantity > maxFreeNumber ? maxFreeNumber : productFreeQuantity;

        // Create a copy of the product with price 0
        const productFree = { ...product, attributes: { ...product.attributes, price: 0 } };
        return { productFree, productFreeQuantityWithLimit };
      }
      return emptyResponse;
    case 'id:06##':
      if (
        promotions.data[0]?.attributes?.quantityX &&
        promotions.data[0]?.attributes?.quantityY &&
        promotions.data[0]?.attributes?.productFree
      ) {
        const productFreeQuantity = Math.floor(quantity / promotions.data[0]?.attributes?.quantityX);
        const maxFreeNumber = promotions.data[0]?.attributes?.maxFreeNumber;
        const productFreeQuantityWithLimit =
          maxFreeNumber && productFreeQuantity > maxFreeNumber ? maxFreeNumber : productFreeQuantity;

        const productFree = promotions.data[0]?.attributes?.productFree?.data;
        productFree.attributes.price_net = 0;
        return { productFree, productFreeQuantityWithLimit };
      }
      return emptyResponse;
    default:
      return emptyResponse;
  }*/
};

export const isProductInCart = (user: IUser, product: IProduct) => {
  return user?.cart?.cart_items.some((cartItem) => cartItem.product.code === product.attributes.code);
};
