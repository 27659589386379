import axios from 'axios';
import { useAuth } from '../../providers/AuthProvider';
import { useEffect, useState } from 'react';
import ICreditLog from '../../lib/interfaces/ICreditLogs';
import { useTranslation } from 'react-i18next';

const { REACT_APP_CUSTOMER_GET_CREDITS_LOGS } = process.env;

const Logs = () => {
  const { t } = useTranslation('PROFILE');
  const [{ token }] = useAuth();
  const [data, setData] = useState<ICreditLog[]>();
  const fetchData = async () => {
    try {
      const response = await axios.get<ICreditLog[]>(REACT_APP_CUSTOMER_GET_CREDITS_LOGS!, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data);
    } catch {}
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="mb-6 rounded-xl border border-gray-300 px-6 py-6">
      <div className="overflow-x-auto m-8">
        <table className="min-w-full bg-white rounded-t-lg overflow-hidden">
          <thead className="bg-gray-50 text-gray-500">
            <tr className="rounded">
              <th className="px-4 py-2"> {t('CREDITS_CONSUMED')}</th>
              <th className="px-4 py-2"> {t('RESIDUAL_CREDITS')}</th>
              <th className="px-4 py-2">{t('OPERATION_TYPE')}</th>
              <th className="px-4 py-2">{t('SEARCH_OBJECT')}</th>
              <th className="px-4 py-2">{t('DATE')}</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index} className="border">
                <td className="px-4 py-2 text-center">{item.credits_consumed}</td>
                <td className="px-4 py-2 text-center">{item.residual_credits}</td>
                <td className="px-4 py-2 text-center">{item.opType}</td>
                <td className="px-4 py-2 text-center">{item.params}</td>
                <td className="px-4 py-2 text-center">{item.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Logs;
