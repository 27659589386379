import { useTranslation } from 'react-i18next';

interface IInfoDetail {
  title: string;
  value: string;
}
interface IInfoDetailBoxProps {
  details: IInfoDetail[];
  tecdocTexts?: string[];
}

const InfoDetailBox = (props: IInfoDetailBoxProps) => {
  const { details, tecdocTexts } = props;
  const { t } = useTranslation('PRODUCTS');

  return (
    <div className="w-full rounded-md border border-gray-200 bg-white py-2 font-medium">
      <dl className="divide-y divide-gray-200">
        {!!tecdocTexts?.length && (
          <div className="py-4 px-4 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="capitalize text-gray-500">{t('DESCRIPTION')}</dt>
            <dd className="mt-1 capitalize text-gray-900 sm:col-span-2 sm:mt-0">{tecdocTexts.join('. ')}</dd>
          </div>
        )}

        {details.map(({ title, value }, key) => (
          <div key={key} className="py-4 px-4 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="capitalize text-gray-500">{title}</dt>
            <dd
              className="mt-1 capitalize text-gray-900 sm:col-span-2 sm:mt-0"
              dangerouslySetInnerHTML={{ __html: value }}
            />
          </div>
        ))}
      </dl>
    </div>
  );
};

export default InfoDetailBox;
