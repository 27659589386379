import { classNames } from '../../lib/utils';
import Spinner from '../Spinner';

interface IPrimaryButton {
  text: string;
  type?: 'button' | 'submit';
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
}

const PrimaryButton = ({
  text,
  type = 'button',
  onClick = () => {},
  disabled = false,
  loading = false,
}: IPrimaryButton) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      className={classNames(
        'flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium uppercase text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2',
        disabled ? 'cursor-not-allowed' : 'hover:bg-red-700',
      )}
    >
      {loading ? <Spinner /> : text}
    </button>
  );
};

export default PrimaryButton;
