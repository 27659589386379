import { useEffect, useState } from 'react';
import { useAuth } from '../../providers/AuthProvider';
import { list as listFiles, upload as uploadFiles, remove as removeFile } from './../../lib/fetch/files';
import ProfilePresentational from './ProfilePresentational';
import { IStrapiFile } from '../../lib/interfaces';
import { filterFiles } from '../../lib/utils';
import { AlertType, useAlert } from '../../providers/AlertProvider';
import { useTranslation } from 'react-i18next';

const { REACT_APP_FILES_PATH_PREFIX } = process.env;
const ACCEPTED_FILES = Object.freeze(['image/png', 'image/jpeg', 'application/pdf', 'text/plain']);

const Profile = () => {
  const [{ token, user }] = useAuth();
  const { uuid } = user || {};
  const [files, setFiles] = useState<IStrapiFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation('PROFILE');

  useEffect(() => {
    uuid && fetchUserFiles();
  }, [uuid]);

  const fetchUserFiles = async () => {
    const { error, data: filesResponse } = await listFiles(token!, uuid!);
    setFiles(error ? [] : filesResponse);
  };

  const onFileChange = async (newFiles: File[]) => {
    setLoading(true);
    const filteredFiles = filterFiles(newFiles, {
      acceptedTypes: ACCEPTED_FILES,
      maxSizeMB: 10,
      unsupportedFileCallback: (file: File, messageKey: string) =>
        dispatchAlertChange({
          open: true,
          message: t(messageKey, { fileName: file.name }),
        }),
    });

    const { id: refId } = user || {};
    const path = REACT_APP_FILES_PATH_PREFIX ? `${REACT_APP_FILES_PATH_PREFIX}/${uuid}` : undefined;
    if (refId && filterFiles.length) {
      await uploadFiles({
        files: filteredFiles,
        refId,
        ref: 'plugin::users-permissions.user',
        field: 'documents',
        path,
      });

      dispatchAlertChange({
        message: t('FILE_UPLOAD_SUCCESS'),
        open: true,
        type: AlertType.Success,
      });

      fetchUserFiles();
    }
    setLoading(false);
  };

  const onFileDelete = async (fileToDelete: IStrapiFile) => {
    setLoading(true);
    await removeFile(token!, fileToDelete);
    dispatchAlertChange({
      message: t('FILE_DELETE_SUCCESS', { name: fileToDelete.name }),
      open: true,
      type: AlertType.Success,
    });
    fetchUserFiles();
    setLoading(false);
  };

  return (
    <ProfilePresentational
      acceptedFiles={ACCEPTED_FILES}
      files={files}
      user={user!}
      loading={loading}
      onFileChange={onFileChange}
      onFileDelete={onFileDelete}
    />
  );
};

export default Profile;
