import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { useAlert } from '../../providers/AlertProvider';
import { PrimaryButtonFull } from '../../components/Buttons';
import Footer from '../../components/Footer';
import InputButtonPresentational from '../../components/Form/InputButtonPresentational';
import Header from '../../components/Header';
import Subtotal from '../../components/Subtotal';
import ShoppingCartTable from '../../components/ProductsTable/ShoppingCartTable';
import { ICart } from '../../lib/fetch/carts';
import { ICartItem } from '../../lib/fetch/cartItems';
import FullScreenLoading from '../../components/FullScreenLoading';
import ShoppingCartLottie from '../../lotties/shopping-cart.json';
import axios from 'axios';
import { useAuth } from '../../providers/AuthProvider';
import { calculateCartTotals } from '../../lib/utils/cart';

const { REACT_APP_RVA_APPROVE_CART_URL, REACT_APP_RVA_DISAPPROVE_CART_URL } = process.env;

interface IShoppingCartPresentational {
  cart?: ICart;
  deliveryAddressId?: number;
  loading?: boolean;
  readOnly?: boolean;
  onAddToCart?: (search: string) => void;
  onItemQuantityChange?: (item: ICartItem, quantity: number) => void;
  onItemRemove?: (item: ICartItem) => void;
  onExcerptChange?: (cartItem: ICartItem, excerpt: boolean) => void;
}

const ShoppingCartPresentational: FC<IShoppingCartPresentational> = (props) => {
  const {
    cart,
    deliveryAddressId,
    loading = false,
    readOnly = true,
    onAddToCart = () => {},
    onItemQuantityChange = () => {},
    onItemRemove = () => {},
    onExcerptChange = () => {},
  } = props;
  const [statufullCart, setStatefullCart] = useState<ICart | undefined>(cart);
  const [{ token, user }] = useAuth();
  let { cart_items: cartItems, shipping: cartShipping } = cart || {};
  cartItems = cartItems || [];
  cartShipping = cartShipping || 0;
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation('SHOPPING_CART');
  const { fido } = user || {};

  const approveCart = async () => {
    try {
      const response = await axios.post(
        REACT_APP_RVA_APPROVE_CART_URL || '',
        {
          cartId: cart?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setStatefullCart(response.data);
    } catch (error) {
      console.error('Error approving cart:', error);
    }
  };

  const rejectCart = async () => {
    try {
      const response = await axios.post(
        REACT_APP_RVA_DISAPPROVE_CART_URL || '',
        {
          cartId: cart?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setStatefullCart(response.data);
    } catch (error) {
      console.error('Error rejecting cart:', error);
    }
  };

  return (
    <div className="min-h-screen">
      <Header />

      <FullScreenLoading
        loading={loading}
        lottie={ShoppingCartLottie}
        title={t('SHOPPING_CART:UPDATING_TITLE')}
        subtitle={t('SHOPPING_CART:UPDATING_SUBTITLE')}
        height={200}
        width={400}
      />

      <div className="py-10 px-4">
        <h2 className="mb-6 text-2xl font-bold text-gray-900">{t('TITLE')}</h2>

        <div className="flex flex-col gap-6 lg:flex-row">
          {readOnly ? (
            <div className="flex h-full min-w-0 flex-1 flex-col">
              <div className="mt-6 border-t py-6">
                <ShoppingCartTable
                  cartItems={cartItems}
                  onItemQuantityChange={() => {}}
                  onItemRemove={() => {}}
                  readOnly={readOnly}
                  onExcerptChange={() => {}}
                />
              </div>
            </div>
          ) : (
            <div className="flex h-full min-w-0 flex-1 flex-col">
              <InputButtonPresentational
                onSubmit={(search) => onAddToCart(search)}
                placeholder={t('PLACEHOLDER_SEARCH')}
                textButton={t('MENU:ADD_TO_CART')}
                type="text"
              />
              <div className="mt-6 border-t py-6">
                <ShoppingCartTable
                  cartItems={cartItems}
                  onItemQuantityChange={onItemQuantityChange}
                  onItemRemove={onItemRemove}
                  onExcerptChange={onExcerptChange}
                />
              </div>
            </div>
          )}

          <aside className="mx-auto w-full overflow-y-auto bg-gray-50 py-6 lg:w-2/6">
            <div className="px-4">
              {/* TODO: delivery estimate */}
              {/*<div className="rounded-md bg-yellow-50 px-2 py-3 text-center shadow-sm w-full border border-yellow-700 mb-6">*/}
              {/*  <p className="text-sm text-yellow-700">*/}
              {/*    {t('ESTIMATE_DELIVERY_FOR')}{' '}*/}
              {/*    <span className="uppercase">domani</span>*/}
              {/*  </p>*/}
              {/*</div>*/}
              <h3 className="mb-4 text-lg font-bold text-gray-900">{t('ORDER_SUMMARY')}</h3>
            </div>
            <Subtotal cartItems={cartItems} shipping={cartShipping} shippingAddress={cart?.shipping_address} />
            <div className="mt-10 px-4">
              {readOnly ? (
                statufullCart?.approved === true ? (
                  <div className="flex">
                    <button className="w-full rounded-md bg-green-500 py-3 mx-4 font-bold text-white" disabled>
                      Approvato
                    </button>
                  </div>
                ) : statufullCart?.approved === false ? (
                  <div className="flex">
                    <button className="w-full rounded-md bg-orange-400 py-3 mx-4 font-bold" disabled>
                      Rifiutato
                    </button>
                  </div>
                ) : (
                  <div className="flex">
                    <button className="w-full rounded-md bg-orange-400 py-3 mx-4 font-bold" onClick={rejectCart}>
                      Rifiuta Carrello
                    </button>
                    <button
                      className="w-full rounded-md bg-green-500 py-3 mx-4 font-bold text-white"
                      onClick={approveCart}
                    >
                      Approva Carrello
                    </button>
                  </div>
                )
              ) : (
                <Link
                  to={
                    '/checkout-shipping' + (deliveryAddressId ? `?${queryString.stringify({ deliveryAddressId })}` : '')
                  }
                  onClick={(e) => {
                    const { total } = calculateCartTotals(cartItems || [], cartShipping);
                    if (fido?.max !== 0 && total > (fido?.remaining || 0)) {
                      e.preventDefault();
                      dispatchAlertChange({
                        open: true,
                        message: t('INSUFFICIENT_FIDO'),
                      });
                      return;
                    }
                    if (cartItems?.length) return;
                    e.preventDefault();
                    dispatchAlertChange({
                      open: true,
                      message: t('EMPTY_CART_ERROR'),
                    });
                  }}
                >
                  <PrimaryButtonFull
                    text={t('CHECKOUT_BTN')}
                    onClick={(e) => {
                      const isCartNotApprovedForAgent = !cart?.approved && user?.role?.type === 'agente';

                      if (user?.customer.status === 3 || !user!.ordersEnabled || isCartNotApprovedForAgent) {
                        e.preventDefault();
                        dispatchAlertChange({
                          open: true,
                          message: isCartNotApprovedForAgent ? t('CART_NOT_APPROVED') : t('CHECKOUT_BTN_DISABLED'),
                        });
                        return;
                      }
                    }}
                  />
                </Link>
              )}
            </div>
          </aside>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ShoppingCartPresentational;
