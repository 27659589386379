import * as t from 'io-ts';
import { MetaModel } from './PaginatedResponse';
import { ImageModel } from './Image';
import { LocalizationModel } from './Localization';

export const NewsPostAttributesModel = t.type({
  title: t.string,
  content: t.string,
  createdAt: t.string,
  updatedAt: t.string,
  date: t.string,
  locale: t.string,
  localizations: LocalizationModel,
  publishedAt: t.string,
  image: ImageModel,
});

export const NewsPostModel = t.type({
  id: t.number,
  attributes: NewsPostAttributesModel,
});

export const PaginatedNewsPostModel = t.type({
  data: t.array(NewsPostModel),
  meta: MetaModel,
});

export type PaginatedNewsPost = t.TypeOf<typeof PaginatedNewsPostModel>;

export type NewsPost = t.TypeOf<typeof NewsPostModel>;
