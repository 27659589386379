import { Trans, useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import CheckBoxPresentational from '../Form/CheckBoxPresentational';
import { Link } from 'react-router-dom';

const Step5 = () => {
  const { t } = useTranslation('ACTIVATION_REQUEST');
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="rounded-md border border-gray-300 px-6 py-6">
      <h3 className="mb-6 text-2xl font-bold text-gray-900">{t('STEP5_TITLE')}</h3>

      <div className="mx-auto mt-4 w-full space-y-4 md:w-2/3 lg:w-1/3">
        <CheckBoxPresentational
          id="privacyPolicy"
          {...register('privacyPolicy', {
            required: t('COMMON:REQUIRED') as string,
          })}
          translabel={
            <Trans i18nKey="ACTIVATION_REQUEST:STEP5_PRIVACY">
              Having read the
              <Link
                className="text-red-600 underline underline-offset-4"
                to="/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </Link>
              from “C.A.T.I. S.p.A.”, I consent to the processing of data
            </Trans>
          }
          hasError={!!errors.privacyPolicy}
          helperText={errors.privacyPolicy?.message as string}
        />

        <CheckBoxPresentational
          id="termsConditions"
          {...register('termsConditions', {
            required: t('COMMON:REQUIRED') as string,
          })}
          translabel={
            <Trans i18nKey="ACTIVATION_REQUEST:STEP5_TERMS">
              Having read the
              <Link
                className="text-red-600 underline underline-offset-4"
                to="/terms-conditions"
                target="_blank"
                rel="noreferrer"
              >
                Terms and conditions
              </Link>
              from “C.A.T.I. S.p.A.”, I consent to the processing of data
            </Trans>
          }
          hasError={!!errors.termsConditions}
          helperText={errors.termsConditions?.message as string}
        />
      </div>
    </div>
  );
};

export default Step5;
