import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IProduct, IPromotion, IPromoConfiguration } from '../../lib/fetch/products';
import { DEFAULT_IMAGE_PLACEHOLDER } from '../../lib/constants';
import { ImageMediaType } from '../../lib/enums';
import { SingleProduct } from './SingleProduct';
import _ from 'lodash';

export { default as HorizontalFilter } from './HorizontalFilter';
export { default as Pagination } from './Pagination';
export { default as WarehouseTable } from './WarehouseTable';

interface IProductsTable {
  onAddToCart: (product: IProduct, quantity: number) => void;
  onFetchCrossReferences?: (productId: number) => Promise<IProduct[]>;
  products: IProduct[];
}

export const isBackgroundCompatible = (thumbnail: string) => {
  const url = (thumbnail || '').split('?').shift();
  const ext = (url || '').split('.').pop();
  return ([ImageMediaType.JPG, ImageMediaType.JPEG, ImageMediaType.PNG] as string[]).includes(
    (ext || '').toUpperCase(),
  );
};
export const getThumbnail = (thumbnail?: string) => {
  if (!thumbnail)
    return (
      <div
        className="h-12 w-12 border border-gray-200 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${DEFAULT_IMAGE_PLACEHOLDER})`,
          minWidth: '3rem',
        }}
      />
    );
  if (isBackgroundCompatible(thumbnail))
    return (
      <div
        className="h-12 w-12 border border-gray-200 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${encodeURI(thumbnail)})`,
          minWidth: '3rem',
        }}
      />
    );
  return <img className="h-12 w-12" style={{ minWidth: '3rem' }} src={encodeURI(thumbnail)} alt="thumbnail" />;
};

const ProductsTable: FC<IProductsTable> = (props) => {
  const { onAddToCart = () => {}, onFetchCrossReferences, products = [] } = props;
  const { t } = useTranslation('PRODUCTS');

  const [productQuantities, setProductQuantities] = useState<
    { id: number; quantity: number; assortedQuantity?: number; touched?: boolean }[]
  >([]);

  const onQuantityChange = (quantity: {
    id: number;
    quantity: number;
    assortedQuantity?: number;
    touched?: boolean;
  }) => {
    let newProductQuantities = [...productQuantities];
    const existingQuantity = newProductQuantities.find((q) => q.id === quantity.id);
    if (existingQuantity) {
      existingQuantity.quantity = quantity.quantity;
    } else {
      newProductQuantities.push(quantity);
    }
    setProductQuantities(newProductQuantities);
    for (const promoType of Object.keys(PromoConfigurationType)) {
      const productsInPromo = products.filter((cartItem) =>
        hasPromo(cartItem, PromoConfigurationType[promoType as keyof typeof PromoConfigurationType]),
      );
      const groupedItems = _.groupBy(
        productsInPromo,
        (item) => item.attributes?.promotions?.data[0]?.promoConfiguration[0]?.id,
      );

      for (const promoId of Object.keys(groupedItems)) {
        const productGroup = groupedItems[promoId];
        const totalQuantity = productGroup.reduce(
          (acc, prod) => acc + (newProductQuantities.find((q) => q.id === prod.id)?.quantity ?? 0),
          0,
        );

        for (const prod of productGroup) {
          const assorted_codes = prod?.attributes?.promotions?.data[0]?.assorted_codes ?? false;
          if (assorted_codes) {
            const existingQuantity = newProductQuantities.find((q) => q.id === prod.id);
            if (existingQuantity) {
              existingQuantity.assortedQuantity = totalQuantity;
            }
          }
        }
      }
    }
  };

  const getPromoConf = (prod: IProduct, promoType: string) => {
    return (prod?.attributes?.promotions?.data[0]?.promoConfiguration as IPromoConfiguration[]).find((conf) => {
      return conf.__component === promoType;
    });
  };

  const hasPromo = (prod: IProduct, promoType: string) => {
    return !!prod.attributes?.promotions?.data?.length && !!getPromoConf(prod, promoType);
  };

  const promotions: Map<number, IPromotion> = new Map();
  for (const product of products) {
    const promotion = product.attributes.promotions.data[0];
    promotion && promotions.set(promotion.id, promotion);
  }

  const PromoConfigurationType = {
    EXTRA_DISCOUNT: 'promo.extra-sconto',
    TYPE_ON_TYPE: 'promo.tipo-su-tipo',
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="relative py-3 pl-2">
                    <span className="sr-only" />
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRODUCT')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('BRAND')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('DELIVERY')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('STOCK')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRICE')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRICE_NET')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('FINAL_PRICE')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('ACTION')}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {products.map((product) => {
                  const productQuantity = productQuantities.find((q) => q.id === product.id);
                  return (
                    <SingleProduct
                      key={product.id}
                      product={product}
                      onAddToCart={onAddToCart}
                      onFetchCrossReferences={onFetchCrossReferences}
                      onQuantityChange={onQuantityChange}
                      assortedQuantity={productQuantity?.assortedQuantity}
                      touched={productQuantity?.touched}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsTable;
