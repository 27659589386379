import { EyeIcon, MapPinIcon } from '@heroicons/react/24/solid';
import { ArrowRightEndOnRectangleIcon } from '@heroicons/react/24/outline';
import { setCustomer } from '../../../lib/fetch/agent';
import { AuthActionType, useAuth } from '../../../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Filters from './Filters';
import axios from 'axios';
import { ICustomerForAgent, ICustomerForAgentResponse } from '../../../lib/interfaces/ICustomerForAgent';
import { CustomerDetailsModal } from '../../../components/CustomerDetails';
import { AgentPromotionsDetailsModal } from '../../../components/AgentPromotions';
import { Pagination } from '../../../components/ProductsTable';

const { REACT_APP_AGENT_GET_CUSTOMERS_URL, REACT_APP_DEFAULT_AGENT_PAGE_SIZE } = process.env;

const CustomersList = () => {
  const [data, setData] = useState<ICustomerForAgent[]>([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageSize, setPageSize] = useState(+REACT_APP_DEFAULT_AGENT_PAGE_SIZE! || 100);
  const [total, setTotal] = useState(0);

  const [filters, setFilters] = useState({
    customer_id: '',
    name: '',
    vat_num: '',
    active: '',
  });
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [detailForModal, setDetailForModal] = useState<ICustomerForAgent>();
  const [promotionsForModal, setPromotionsForModal] = useState<ICustomerForAgent>();
  const [isPromotionsModalOpen, setIsPromotionsModalOpen] = useState(false);

  const navigate = useNavigate();
  const [{ token }, dispatchAuthChange] = useAuth();

  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const timeout = setTimeout(() => {
      fetchData();
    }, 1000);

    setDebounceTimeout(timeout);

    return () => {
      clearTimeout(timeout);
    };
  }, [filters]);

  useEffect(() => {
    handlePageChange({
      page: page.toString(),
      pageSize: pageSize.toString(),
    });
  }, [page]);

  const fetchData = async () => {
    try {
      const response = await axios.get<ICustomerForAgentResponse>(REACT_APP_AGENT_GET_CUSTOMERS_URL!, {
        params: filters,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data.customersWithPromotions ?? []);
      const { page, pageSize, pageCount, total } = response.data.pagination;
      setPage(page);
      setPageSize(pageSize);
      setPageCount(pageCount);
      setTotal(total);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleFilterChange = (filterName: string, value: string) => {
    setFilters({
      ...filters,
      [filterName]: value,
    });
    setPage(1);
  };

  const handlePageChange = (newFilters: { [key: string]: string } = {}) => {
    setFilters({
      ...filters,
      ...newFilters,
    });
  };

  const openPromotionsModal = async (item: ICustomerForAgent) => {
    setPromotionsForModal(item);
    setIsPromotionsModalOpen(true);
  };

  const closePromotionsModal = () => {
    setIsPromotionsModalOpen(false);
  };

  const openDetailModal = (item: ICustomerForAgent) => {
    setDetailForModal(item);
    setIsDetailModalOpen(true);
  };

  const closeDetailModal = () => {
    setIsDetailModalOpen(false);
  };

  const impersonateCustomer = async (customerId: number) => {
    const { data: updatedUser } = await setCustomer(token!, customerId);
    dispatchAuthChange({
      type: AuthActionType.SetUser,
      user: updatedUser,
    });
    navigate('/search-results');
  };

  return (
    <div className="overflow-x-auto m-8">
      <Filters filters={filters} onFilterChange={handleFilterChange} />
      <CustomerDetailsModal
        isOpen={isDetailModalOpen}
        onRequestClose={closeDetailModal}
        customerDetailsProps={detailForModal}
      />
      <AgentPromotionsDetailsModal
        isOpen={isPromotionsModalOpen}
        onRequestClose={closePromotionsModal}
        promotionsDetailsProps={promotionsForModal}
      />
      <table className="min-w-full bg-white rounded-t-lg overflow-hidden">
        <thead className="bg-gray-50 text-gray-500">
          <tr className="rounded">
            <th className="px-4 py-2">INFOS</th>
            <th className="px-4 py-2">OFFICE</th>
            <th className="px-4 py-2">CATEGORY</th>
            <th className="px-4 py-2">STATE</th>
            <th className="px-4 py-2">CLIENT PROMOTIONS</th>
            <th className="px-4 py-2">AGENT PROMOTIONS</th>
            {/*<th className="px-4 py-2">CLUSTER LIST</th>*/}
            <th className="px-4 py-2">ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className="border">
              <td className="px-4 py-2">
                <p className="font-bold">{item?.name}</p>
                <p className="text-sm text-gray-400">{item?.phone1}</p>
              </td>
              <td className="px-4 py-2 text-sm text-gray-400">
                <div className="flex align-center my-2">
                  <MapPinIcon className="h-4 w-4 mr-1 mt-1 flex-shrink-0" />
                  {item?.address}
                </div>
              </td>
              <td className="px-4 py-2 text-center">
                <span className="bg-gray-200 text-gray-700 py-1 px-3 rounded-full text-xs mr-2 mb-2 whitespace-nowrap inline-block">
                  {item.customer_class_id?.name}
                </span>
              </td>
              <td className="px-4 py-2 whitespace-nowrap">
                {item.active ? (
                  <>
                    <span className="text-green-500 text-2xl mr-2">●</span>
                    <span className="text-green-500">Active</span>
                  </>
                ) : (
                  <>
                    <span className="text-red-500 text-2xl mr-2">●</span>
                    <span className="text-red-500">Inactive</span>
                  </>
                )}
              </td>
              <td className="px-4 py-2 text-center">
                <span
                  className={`bg-gray-100 text-red-500 py-1 px-3 w-7 h-7 rounded-full shadow-md text-xs inline-flex items-center justify-center font-bold ${
                    (item.promotions.customer_promotions?.length ?? 0) === 0
                      ? 'cursor-not-allowed opacity-50'
                      : 'cursor-pointer'
                  }`}
                  onClick={() => (item.promotions.customer_promotions?.length ?? 0) > 0 && openPromotionsModal(item)}
                >
                  {item.promotions.customer_promotions?.length ?? 0}
                </span>
              </td>
              <td className="px-4 py-2 text-center">
                <span
                  className={`bg-gray-100 text-red-500 py-1 px-3 w-7 h-7 rounded-full shadow-md text-xs inline-flex items-center justify-center font-bold ${
                    (item.promotions.agent_promotions?.length ?? 0) === 0
                      ? 'cursor-not-allowed opacity-50'
                      : 'cursor-pointer'
                  }`}
                  onClick={() => (item.promotions.agent_promotions?.length ?? 0) > 0 && openPromotionsModal(item)}
                >
                  {item.promotions.agent_promotions?.length ?? 0}
                </span>
              </td>
              {/*<td className="px-4 py-2">
                {item.clusterList.map((cluster, i) => (
                  <div key={i} className="text-gray-400">
                    {cluster}
                  </div>
                ))}
              </td>*/}
              <td className="px-4 py-2 flex">
                <button className="bg-gray-200 text-black rounded-md p-2 mr-2" onClick={() => openDetailModal(item)}>
                  <EyeIcon className="h-5 w-5" />
                </button>
                <button className="bg-red-500 text-white rounded-md p-2" onClick={() => impersonateCustomer(item.id)}>
                  <ArrowRightEndOnRectangleIcon className="h-5 w-5" />
                </button>
              </td>
            </tr>
          ))}
          <Pagination
            currentItems={data}
            onPageChange={setPage}
            onPageSizeChange={setPageSize}
            page={page}
            pageCount={pageCount}
            pageSize={pageSize}
            total={total}
          />
        </tbody>
      </table>
    </div>
  );
};

export default CustomersList;
