import { useAuth } from '../../providers/AuthProvider';
import { IUser } from '../interfaces';
import ICustomer from '../interfaces/ICustomer';

const defaultCompare = (inputValue: any, requiredValue: any) => inputValue === requiredValue;

const useUserHasRequiredOptions = (
  requiredOptions: Partial<IUser>,
  compareOption: (inputValue: any, requiredValue: any) => boolean = defaultCompare,
) => {
  const [{ user }] = useAuth();
  return Object.entries(requiredOptions).every(([key, value]) => compareOption(user?.[key as keyof IUser], value));
};

const useCustomerHasRequiredOptions = (
  requiredOptions: Partial<ICustomer>,
  compareOption: (inputValue: any, requiredValue: any) => boolean = defaultCompare,
) => {
  const [{ user }] = useAuth();
  return Object.entries(requiredOptions).every(([key, value]) =>
    compareOption(user?.customer?.[key as keyof ICustomer], value),
  );
};

export { useUserHasRequiredOptions, useCustomerHasRequiredOptions };
