import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../providers/AuthProvider';
import FullScreenLoading from '../FullScreenLoading';

const PrivateRoute: React.FC<{
  element: React.ReactElement;
  publicRedirect?: string;
  hasUserRequiredOptions?: boolean;
  hasCustomerRequiredOptions?: boolean;
}> = (props) => {
  const { t } = useTranslation('COMMON');
  const [{ user, appLoaded, isAuthenticated }] = useAuth();
  const {
    element: PrivateElement,
    publicRedirect = '/',
    hasUserRequiredOptions = true,
    hasCustomerRequiredOptions = true,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.isSingleUsePassword) {
      navigate('/profile/reset-temporary-password');
    }
  }, [location.pathname, user]);

  if (appLoaded)
    return isAuthenticated && user?.customer && hasUserRequiredOptions && hasCustomerRequiredOptions ? (
      PrivateElement
    ) : (
      <Navigate replace to={publicRedirect} />
    );

  return <FullScreenLoading loading title={t('LOADING')} subtitle={t('LOADING_SUBTITLE')} />;
};

export default PrivateRoute;
