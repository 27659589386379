import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useShoppingCartOverlay } from '../../providers/ShoppingCartOverlayProvider';
import ProductDetailPresentational from './ProductDetailPresentational';
import { AlertType, useAlert } from '../../providers/AlertProvider';
import { useAuth } from '../../providers/AuthProvider';
import { getCrossReferences, getOne as getProduct, IProduct } from '../../lib/fetch/products';
import { cartUtils, IAddToCartWithFeedback } from '../../lib/utils';
import i18n from '../../i18n';
import AddToCartModal from '../../components/Modals/AddToCartModal';
import useAddToCartModal from '../../hooks/useAddToCartModal';
import { isProductInCart } from '../../lib/utils/cart';

const ProductDetail = () => {
  const [{ token, user }, dispatchAuthChange] = useAuth();
  const [, dispatchAlertChange] = useAlert();
  const navigation = useNavigate();
  const { id } = useParams();
  const [, dispatchCartOverlayChange] = useShoppingCartOverlay();
  const [adding, setAdding] = useState(false);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState<IProduct>();
  const [crossReferences, setCrossReferences] = useState<IProduct[]>([]);
  const [loadingCrossReferences, setLoadingCrossReferences] = useState(false);
  const { isModalOpen, currentProduct, currentQuantity, openModal, closeModal } = useAddToCartModal();

  const { t } = useTranslation('PRODUCTS');
  const lang = i18n.languages[0];
  const onAddToCart = async (product: IProduct, quantity: number) => {
    if (isProductInCart(user!, product)) {
      openModal(product, quantity);
      return;
    }
    await addToCart(product, quantity);
  };
  const addToCart = async (product: IProduct, quantity: number) => {
    let quantityForced = quantity;
    const minQuantity = product?.attributes?.sales_package_sizes || 1;
    if (
      product?.attributes?.sales_package_sizes &&
      Math.floor(product?.attributes?.sales_package_sizes) !== 1 &&
      quantity % product.attributes.sales_package_sizes !== 0
    ) {
      if (quantity < minQuantity) {
        quantityForced = minQuantity;
      } else if (quantity % minQuantity !== 0) {
        quantityForced = Math.ceil(quantity / minQuantity) * minQuantity;
      }
    }

    return cartUtils.addToCartWithFeedback({
      dispatchAlertChange,
      dispatchAuthChange,
      dispatchCartOverlayChange,
      products: [{ product, quantity: quantityForced }],
      setAdding,
      t,
      token,
      user,
      lang,
      onClose:
        quantityForced > quantity
          ? () => {
              dispatchAlertChange({
                type: AlertType.Info,
                open: true,
                message: t('SHOPPING_CART:PRODUCT_MIN_QUANTITY') + ' (' + minQuantity + ')',
              });
            }
          : () => {},
    } as IAddToCartWithFeedback);
  };

  const fetchCrossReferences = async () => {
    setLoadingCrossReferences(true);
    const { error, data: responseData } = await getCrossReferences(token!, product!.id, lang);
    setLoadingCrossReferences(false);
    if (error || !responseData?.data) {
      setCrossReferences([]);
    }
    setCrossReferences(responseData.data);
  };

  useEffect(() => {
    const productId = +id!;
    productId &&
      (async () => {
        setLoading(true);
        const {
          data: { data },
          error,
        } = await getProduct(token!, productId, lang);
        if (error || !data) {
          setLoading(false);
          navigation('/error', { replace: true });
        }
        setProduct(data);
        setLoading(false);
      })();
  }, [id]);

  useEffect(() => {
    if (!product) return;
    fetchCrossReferences();
  }, [product]);
  const handleConfirm = async () => {
    closeModal();
    if (currentProduct) {
      await addToCart(currentProduct, currentQuantity);
    }
  };
  return (
    <>
      <ProductDetailPresentational
        adding={adding}
        onAddToCart={onAddToCart}
        loading={loading}
        product={product}
        crossReferences={crossReferences}
        loadingCrossReferences={loadingCrossReferences}
      />
      <AddToCartModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        onConfirm={handleConfirm}
        product={currentProduct}
        quantity={currentQuantity}
      />
    </>
  );
};

export default ProductDetail;
