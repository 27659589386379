import { useState } from 'react';
import { IProduct } from '../lib/fetch/products';

const useAddToCartModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<IProduct | null>(null);
  const [currentQuantity, setCurrentQuantity] = useState(1);

  const openModal = (product: IProduct, quantity: number) => {
    setCurrentProduct(product);
    setCurrentQuantity(quantity);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentProduct(null);
    setCurrentQuantity(1);
  };

  return {
    isModalOpen,
    currentProduct,
    currentQuantity,
    openModal,
    closeModal,
  };
};

export default useAddToCartModal;
