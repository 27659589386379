import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import InputPresentational from '../Form/InputPresentational';
import { FC } from 'react';

interface IStep2 {
  isEditing?: boolean;
}

const Step2: FC<IStep2> = ({ isEditing = false }) => {
  const { t } = useTranslation('ACTIVATION_REQUEST');
  const { register } = useFormContext();

  return (
    <div className="rounded-md border border-gray-300 px-6 py-6">
      <h3 className="mb-6 text-2xl font-bold text-gray-900">{t('STEP2_TITLE')}</h3>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
        <div>
          <InputPresentational
            label={t('STEP2_CODE')}
            id="customerCode"
            {...register('customerCode')}
            autoComplete="on"
          />
          <p className="mt-2 text-sm text-gray-500">{t('STEP2_CODE_DESCRIPTION')}</p>
        </div>
        <InputPresentational
          label={t('STEP2_BUSINESS_NAME')}
          id="companyName"
          {...register('companyName')}
          autoComplete="organization"
        />
      </div>
    </div>
  );
};

export default Step2;
