import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay, Mousewheel } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
import 'swiper/css/effect-coverflow';
import 'swiper/css/mousewheel';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import 'swiper/css/grid';

import './carousel.css';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { IPromotion } from '../../../lib/fetch/products';
const { REACT_APP_MEDIA_LIBRARY_URL } = process.env;

interface IPromomotionsCarousel {
  promotions: IPromotion[] | [];
}

const PromotionCarousel: React.FC<IPromomotionsCarousel> = (props: IPromomotionsCarousel) => {
  const { promotions } = props;
  return (
    <section className="w-full bg-gray-50 py-8">
      <div className="px-2 sm:px-4 max-w">
        <h2 className="text-center text-2xl font-semibold text-gray-700 mb-8">
          Scopri le nostre offerte e promozioni esclusive!
        </h2>
        <Swiper
          modules={[Pagination, Navigation, Autoplay, Mousewheel]}
          mousewheel={true}
          centeredSlides={false}
          watchOverflow={true}
          className="w-full"
          pagination={{
            enabled: true,
            clickableClass: 'swiper-pagination-clickable carousel-bullets-wrapper',
            dynamicBullets: true,
            clickable: true,
          }}
          autoplay={{
            pauseOnMouseEnter: true,
          }}
          grabCursor={promotions.length > 1}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
              slidesPerGroup: 1,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 15,
              slidesPerGroup: 2,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
              slidesPerGroup: 3,
            },

            1280: {
              slidesPerView: 4,
              spaceBetween: 20,
              slidesPerGroup: 4,
            },
          }}
          loop={false}
        >
          {promotions.map((p, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="relative">
                  <img
                    src={`${REACT_APP_MEDIA_LIBRARY_URL}${p.image.url}`}
                    alt={p.image?.name ?? ''}
                    className="w-full h-48 object-cover rounded-lg"
                  />
                  <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 to-transparent p-4 rounded-b-lg">
                    <div className="bg-white/90 text-gray-800 px-3 py-1 rounded-full text-[10px] sm:text-xs inline-block mb-1">
                      <span className="mx-1">Dal</span>
                      <span className="font-bold">{new Date(p.validFrom).toLocaleDateString()}</span>
                      <span className="mx-1">al</span>
                      <span className="font-bold">{new Date(p.validTo).toLocaleDateString()}</span>
                    </div>
                    <h3 className="font-bold text-sm sm:text-lg text-white">{p.title}</h3>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="text-center mt-8">
          <Link
            className="bg-gray-800 text-white px-6 py-2 rounded-md hover:bg-gray-700 transition-colors"
            to={ROUTES.PROMOTIONS.LIST}
          >
            Promozioni
          </Link>
        </div>
      </div>
    </section>
  );
};

export default PromotionCarousel;
