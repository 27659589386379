import { useTranslation } from 'react-i18next';
import { SingleProduct } from '../../../components/ProductsTable/SingleProduct';
import Spinner from '../../../components/Spinner';
import { IProduct } from '../../../lib/fetch/products';

interface ICrossReferencesProps {
  crossReferences: IProduct[];
  onAddToCart?: (product: IProduct, quantity: number) => void;
  loading?: boolean;
}

const CrossReferences = (props: ICrossReferencesProps) => {
  const { crossReferences, onAddToCart = () => {}, loading = false } = props;
  const { t } = useTranslation('PRODUCTS');

  return loading ? (
    <div className="mx-auto">
      <Spinner
        style={{
          color: 'rgb(220 38 38)',
          width: 30,
          height: 30,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
    </div>
  ) : !!crossReferences.length ? (
    <div className="flex flex-col gap-4 md:flex-row">
      <div className="flex-1">
        <div className="text-sm">
          <div className="overflow-hidden border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRODUCT')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('BRAND')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('DELIVERY')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('STOCK')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRICE')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRICE_NET')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('FINAL_PRICE')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('ACTION')}
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white">
                {crossReferences.map((product) => (
                  <SingleProduct key={product.id} product={product} onAddToCart={onAddToCart} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>{t('NO_RESULT')}</>
  );
};

export default CrossReferences;
