import { useEffect } from 'react';
import { AuthActionType, useAuth } from '../../../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import RedirectPresentational from '../../Redirect/RedirectPresentational';
import { IUser } from '../../../lib/interfaces';
import redirectToSofinnV2 from '../../../lib/fetch/sofinn/auth/redirectV2';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const RedirectSofinn: React.FC = () => {
  const navigate = useNavigate();
  const [{ sofinnUser, sofinnToken }, dispatchAuthChange] = useAuth();
  const { t } = useTranslation('REDIRECT_SOFINN');

  const handleRedirect = ({
    _sofinnToken,
    _sofinnUser,
  }: {
    _sofinnToken: string | null;
    _sofinnUser?: IUser | null;
  }) => {
    const shouldUserBeInformedForNewCredentials =
      _sofinnUser && _sofinnToken && !_sofinnUser.isUserInformedAboutNewCredentials;
    const shouldserBeRedirectedToLoginPage = _.isUndefined(_sofinnUser);

    //l'utente viene ridirezionato a sofinn se non ha l'utente oppure se ce l'ha e ha completato la procedura di cambio password
    const shouldUserBeRedirectedToSofinn =
      _sofinnToken && (_.isNull(_sofinnUser) || (_sofinnUser && _sofinnUser.hasCompletedSofinnPwChangeProcedure));

    //l'utente viene mandato alla schermata di cambio password se non lo ha ancora fatto
    const shouldUserBeRedirectedToChangePassword =
      _sofinnToken && _sofinnUser && !_sofinnUser.hasCompletedSofinnPwChangeProcedure;

    //Se la data di scadenza per il cambio password non è ancora passata, l'utente può saltare questo step
    const canUserSkipPwChangeProcedure = moment(_sofinnUser?.passwordExpiryDate).isAfter(moment.now());

    //Se l'utente ha password usa e getta va direzionato al form di cambio password temporanea
    const shouldUserChangeTemporaryPassword = _sofinnUser?.isSingleUsePassword;

    //se c'è il token, ma non c'è lo user significa che l'utente deve fare direttamente il redirect a sofinn
    if (shouldUserBeRedirectedToSofinn) {
      redirectToSofinnV2({ sofinnToken: String(_sofinnToken), dispatchAuthChange });
    }

    //se c'è sia il token che l'utente allora l'utente in questione è stato registrato nel nostro sistema
    //proponiamo cambio password
    if (shouldUserBeInformedForNewCredentials) {
      return navigate('/cati-b2b/info-new-username');
    }

    /**
     * Redirect cambio password
     */
    if (shouldUserBeRedirectedToChangePassword) {
      if (canUserSkipPwChangeProcedure) navigate('/cati-b2b/question-reset-password');
      else navigate('/cati-b2b/reset-password');
    }

    /**
     * Redirect cambio password temporanea
     */
    if (shouldUserChangeTemporaryPassword) {
      navigate('/cati-b2b/reset-temporary-password');
    }

    if (shouldserBeRedirectedToLoginPage) {
      /**
       * Redirect alla pagina di login
       */
      dispatchAuthChange({ type: AuthActionType.Logout });
      navigate('/cati-b2b/login');
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!sofinnToken && !sofinnUser) {
        navigate('/cati-b2b/login');
      }
    }, 5000);

    if (sofinnToken) {
      clearInterval(timer);

      handleRedirect({ _sofinnToken: sofinnToken, _sofinnUser: sofinnUser });
    }
  }, [sofinnUser]);

  return <RedirectPresentational message={t('PLEASE_WAIT_MSG')} />;
};

export default RedirectSofinn;
