export enum ImageMediaType {
  BMP = 'BMP',
  JPG = 'JPG',
  JPEG = 'JPEG',
  PNG = 'PNG',
}

export enum WhereToSearch {
  Code = 'code',
  PartialCode = 'partialCode',
  Description = 'description',
  Everywhere = 'everywhere',
}

export enum PaymentMethod {
  Card = 'CARD', // Carte di credito
  Paypal = 'PAYPAL', // PayPal
  WireTransfer = 'WIRE-TRANSFER', // Bonifico bancario
  Cash = 'CASH', // Pagamento alla consegna
}

export enum OrderStatus {
  NotPaid = 'NOT_PAID',
  Processing = 'PROCESSING',
  Paid = 'PAID',
  Shipped = 'SHIPPED',
  Delivered = 'DELIVERED',
  Canceled = 'CANCELED',
  Error = 'ERROR',
}

export enum PaymentStatus {
  Error = 'ERROR',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Succeeded = 'SUCCEEDED',
}

export enum ProductDetailsTab {
  TECHNICAL_SPECS = 'TECHNICAL_SPECS',
  CROSS_REFERENCES = 'CROSS_REFERENCES',
  PROMOTIONS_TAB = 'PROMOTIONS_TAB',
  OEM_CODES = 'OEM_CODES',
}

export enum Language {
  IT = 'it',
  EN = 'en',
  FR = 'fr',
}
