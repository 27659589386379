import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import InputPresentational from '../Form/InputPresentational';
import { FC } from 'react';

interface IStep1 {
  isEditing?: boolean;
}

const Step1: FC<IStep1> = ({ isEditing = false }) => {
  const { t } = useTranslation('ACTIVATION_REQUEST');
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="rounded-md border border-gray-300 px-6 py-6">
      <h3 className="mb-6 text-2xl font-bold text-gray-900">{t('STEP1_TITLE')}</h3>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
        <InputPresentational
          label={`${t('STEP1_NAME')} *`}
          id="name"
          {...register('name', {
            required: t('COMMON:REQUIRED') as string,
          })}
          autoComplete="given-name"
          hasError={!!errors.name}
          helperText={errors.name?.message as string}
        />
        <InputPresentational
          label={`${t('STEP1_SURNAME')} *`}
          id="surname"
          {...register('surname', {
            required: t('COMMON:REQUIRED') as string,
          })}
          autoComplete="family-name"
          hasError={!!errors.surname}
          helperText={errors.surname?.message as string}
        />
        <InputPresentational
          label={`${t('STEP1_EMAIL')} *`}
          id="email"
          disabled={isEditing}
          {...register('email', {
            required: t('COMMON:REQUIRED') as string,
          })}
          type="email"
          autoComplete="email"
          hasError={!!errors.email}
          helperText={errors.email?.message as string}
        />
        <InputPresentational label={t('STEP1_VAT')} id="vatNumber" {...register('vatNumber')} autoComplete="on" />
        <InputPresentational
          label={t('STEP1_FISCAL_CODE')}
          id="fiscalCode"
          {...register('fiscalCode')}
          autoComplete="on"
        />
        <InputPresentational label={t('STEP1_PHONE')} id="phone" {...register('phone')} type="tel" autoComplete="tel" />
      </div>
    </div>
  );
};

export default Step1;
