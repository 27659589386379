import qs from 'qs';
import { list } from '../../utils/strapi';
import { NewsPostModel } from '../../models/NewsPost';
import i18n from '../../../i18n';

const { REACT_APP_NEWS_URL } = process.env;

interface SearchParams {
  page: number | undefined;
  limit?: number;
  sort?: string | string[];
}

const fetchNews = async (token: string | null, params: SearchParams) => {
  const { page = 1, limit = 6, sort } = params;
  const pagination = { page, pageSize: limit };
  const lang = i18n.languages[0];

  const query = qs.stringify(
    {
      pagination,
      populate: '*',
      sort,
      locale: lang,
    },
    { encodeValuesOnly: true },
  );

  return await list(`${REACT_APP_NEWS_URL!}?${query}`, token, NewsPostModel);
};

export default fetchNews;
