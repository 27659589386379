import { XMarkIcon } from '@heroicons/react/24/outline';

interface IFileUploadPrev {
  file: File;
  onDelete: (file: File) => void;
}

const FileUploadPrev = ({ file, onDelete = (file: File) => {} }: IFileUploadPrev) => {
  return (
    <div className="mb-2 flex flex-row items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="mr-2 h-7 w-7 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <p className="mb-0 text-base font-normal text-gray-700">{file.name}</p>
      <button
        type="button"
        onClick={() => onDelete(file)}
        className=" ml-4 inline-flex items-center rounded-full border border-transparent p-0.5 text-red-600 hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
      >
        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </div>
  );
};

export default FileUploadPrev;
