import React, { createContext, useContext, useReducer } from 'react';

export interface ShoppingCartOverlayAction {
  open?: boolean;
}

interface ShoppingCartOverlayState {
  open: boolean;
}

const INITIAL_STATE = Object.freeze<ShoppingCartOverlayState>({
  open: false,
});

const reducer = (state: ShoppingCartOverlayState, action: ShoppingCartOverlayAction = {}) => {
  return {
    ...state,
    ...action,
  };
};

const reset = () => ({ ...INITIAL_STATE });

const dispatchWrapper =
  (actualDispatch = (_action?: ShoppingCartOverlayAction) => {}) =>
  (action: ShoppingCartOverlayAction) =>
    actualDispatch(action);

const ShoppingCartOverlayContext = createContext<
  [ShoppingCartOverlayState, (action: ShoppingCartOverlayAction) => void]
>([{ ...INITIAL_STATE }, dispatchWrapper()]);

const ShoppingCartOverlayProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(reducer, { ...INITIAL_STATE }, reset);

  return (
    <ShoppingCartOverlayContext.Provider value={[state, dispatchWrapper(dispatch)]}>
      {children}
    </ShoppingCartOverlayContext.Provider>
  );
};

const useShoppingCartOverlay = () => useContext(ShoppingCartOverlayContext);

export { ShoppingCartOverlayProvider, useShoppingCartOverlay };
