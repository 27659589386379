import { ICartItem } from '../fetch/cartItems';
import {
  IPromoEveryTotProductFree,
  IPromoExtraDiscount,
  IPromoExtraDiscountPrice,
  IPromotion,
  IPromoTypeOnType,
  PromoConfigurationType,
} from '../fetch/products';
import currency from './currency';

export const getPromotionTypeCode = (promotionType: string) => {
  const PROMOTION_TYPE_REGEX = /id:(.*?)##/g;
  const promotionCode = PROMOTION_TYPE_REGEX.exec(promotionType);

  return promotionCode ? promotionCode[0] : null;
};

export const getPromoDescription = (promo: IPromotion, lang: string) => {
  if (promo?.promoConfiguration?.length > 0) {
    switch (promo.promoConfiguration[0].__component) {
      case PromoConfigurationType.EXTRA_DISCOUNT:
        const extraDiscount = promo.promoConfiguration[0] as IPromoExtraDiscount;
        let description = '<ul style="list-style: inside;">';
        extraDiscount.extraScontoFasce.forEach((extraScontoFascia) => {
          description += `<li>Con un acquisto minimo di ${extraScontoFascia.minimoDi} pz otterrai un Extra sconto del ${extraScontoFascia.extraSconto}%</li>`;
        });
        return description + '</ul>';
      case PromoConfigurationType.TYPE_ON_TYPE:
        const typeOnType = promo.promoConfiguration[0] as IPromoTypeOnType;
        return `Acquistando ${typeOnType.ogni} pz di questo articolo, ne avrai ${typeOnType.inOmaggio} in omaggio`;
      case PromoConfigurationType.EVERY_X_FOR_FREE:
        const everyXForFree = promo.promoConfiguration[0] as IPromoEveryTotProductFree;
        return `Se acquisti ${everyXForFree.ogni} pz di questo articolo avrai in omaggio un ${everyXForFree.inOmaggio.code}`;
    }
  } else {
    return 'No valid promo';
  }
};

export const getCartItemList = (cartItem: ICartItem) => {
  // Netto nel carrello
  const {
    quantity,
    product: { price_list },
  } = cartItem;

  return price_list ? `${currency(price_list)} (${quantity}x)` : '-';
};

export const getCartItemNet = (cartItem: ICartItem) => {
  // Netto nel carrello
  const { quantity } = cartItem;

  const productPrice = getProductPrice(cartItem);

  return `${currency(productPrice)} (${quantity}x)`;
};

export const getCartItemFinal = (cartItem: ICartItem) => {
  //Finale nel carrello

  const { quantity } = cartItem;

  const productPrice = getProductPrice(cartItem);

  return `${currency(productPrice * quantity)}`;
};

export const getProductPrice = (cartItem: ICartItem) => {
  const { price, quantity, promotions, priceDiscounted } = cartItem;

  if (!promotions?.data.length) {
    if (price || price === 0) return price;
    else return 0;
  } else {
    if (priceDiscounted) {
      return priceDiscounted;
    }
    const promo = promotions.data[0];

    if (promo?.promoConfiguration?.length > 0) {
      switch (promo.promoConfiguration[0].__component) {
        case PromoConfigurationType.EXTRA_DISCOUNT:
          const extraDiscount = promo.promoConfiguration[0] as IPromoExtraDiscount;
          const fasciaExtraSconto = extraDiscount.extraScontoFasce
            .slice()
            .sort((a, b) => b.minimoDi - a.minimoDi)
            .find((fascia) => fascia.minimoDi <= quantity);
          if (fasciaExtraSconto) {
            return price - price * (fasciaExtraSconto.extraSconto / 100)!;
          } else {
            return price!;
          }
      }
    }
    return price;
  }
};

export const getExtraDiscountPrice = (priceDiscounted: IPromoExtraDiscountPrice[] | null, quantity: number = 1) => {
  if (!priceDiscounted || !priceDiscounted.length) return null;
  return (
    priceDiscounted?.sort((a, b) => b.minimoDi - a.minimoDi)?.find((fascia) => fascia.minimoDi <= quantity)?.prezzo ??
    null
  );
};
